@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');

html,
body,
#root,
.app,
.content {
    height: 100%;
    width: 100%;
    font-family: 'Source Sans Pro', sans-serif;
}

.app {
    display: flex;
    position: relative;
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
a {
    text-decoration: none;
}
.donate-wrapper {
    width: 100%;
    display: 'flex';
    justify-content: 'center';
    padding: 50px 300px 0 300px;
}

.donate-wrapper-top {
    text-align: center;
    border-bottom: 5px solid #f4f3f3;
    position: relative;
    margin: 15px 0px 10px 0px;
}
.background-top1 {
    position: absolute;
    top: -33px;
    left: 179px;
    height: 149px !important;
    width: 190px !important;
}
.donate-wrapper-top img {
    width: 80px;
    height: 80px;
    border-radius: 100%;
}

.name-donate {
    color: #f0564a;
    font-weight: bold;
    font-size: 18px;
}
.money-donate {
    color: orange;
    font-weight: bold;
    line-height: 0rem;
    font-size: 16px;
}
.box-avatar {
    position: relative;
}
.avatar-donate-item {
    border-radius: 50%;
    width: 42px;
    height: 45px;
}
.donate-wrapper-bottom-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
}
.donate-wrapper-bottom-item-left {
    display: flex;
    align-items: center;
    gap: 10px;
}
.rank-donate-item {
    position: absolute;
    top: -5px;
    left: -3px;
    width: 48px;
    height: 52px;
}
.name-donate-item {
    color: #5f67f8;
    font-weight: 600;
    font-size: 16px;
}
.order-donate-item {
    font-weight: 600;
    font-size: 16px;
}
.donate-wrapper-bottom-item-right {
    font-weight: 600;
    font-size: 16px;
}
.play-btn {
    width: 70px;
    height: 70px;
    background: radial-gradient(rgba(255, 0, 128, 0.8) 60%, rgba(255, 255, 255, 1) 62%);
    border-radius: 50%;
    margin: -122px 16px;
    box-shadow: 0px 0px 25px 3px rgba(255, 0, 128, 0.8);
    cursor: pointer;
    position: absolute;
}

/* triangle */
.play-btn::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-40%) translateY(-50%);
    transform: translateX(-40%) translateY(-50%);
    transform-origin: center center;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 25px solid #fff;
    z-index: 100;
    -webkit-transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

/* pulse wave */
.play-btn:before {
    content: '';
    position: absolute;
    width: 150%;
    height: 150%;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation: pulsate1 2s;
    animation: pulsate1 2s;
    -webkit-animation-direction: forwards;
    animation-direction: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: steps;
    animation-timing-function: steps;
    opacity: 1;
    border-radius: 50%;
    border: 5px solid rgba(255, 255, 255, 0.75);
    top: -30%;
    left: -30%;
    background: rgba(198, 16, 0, 0);
}
.backgroundWrapper {
    padding: 30px;
    background-attachment: 'fixed';
    background-position: '50%';
    background-repeat: 'no-repeat';
    background-size: 'cover';
}
.banner-hero__img {
    animation: moveUpDown 6s linear infinite;
}
.banner__title {
    font-size: 64px;
    color: #000336;
    font-weight: bold;
    line-height: 1.167;
}
@keyframes moveUpDown {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(50px);
    }
}
@-webkit-keyframes pulsate1 {
    0% {
        -webkit-transform: scale(0.6);
        transform: scale(0.6);
        opacity: 1;
        box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75), 0px 0px 25px 10px rgba(255, 255, 255, 0.75);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0;
        box-shadow: none;
    }
}

@keyframes pulsate1 {
    0% {
        -webkit-transform: scale(0.6);
        transform: scale(0.6);
        opacity: 1;
        box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75), 0px 0px 25px 10px rgba(255, 255, 255, 0.75);
    }
    100% {
        -webkit-transform: scale(1, 1);
        transform: scale(1);
        opacity: 0;
        box-shadow: none;
    }
}
@media screen and (max-width: 500px) {
    .donate-wrapper {
        padding: 50px 5px 0 5px;
    }
    .avatar-donate-item {
        width: 28px;
        height: 31px;
    }
    .rank-donate-item {
        position: absolute;
        top: -5px;
        left: -3px;
        width: 34px;
        height: 38px;
    }
    .name-donate-item {
        font-size: 13px;
    }
    .play-btn {
        width: 60px;
        height: 60px;
        margin: -80px 16px;
    }
    .backgroundWrapper {
        padding: 60px 30px 60px 30px;
    }
    .background-top1 {
        position: absolute;
        top: -18px;
        left: 114px;
        height: 80px !important;
        width: 121px !important;
    }
    .donate-wrapper-top img {
        width: 60px;
        height: 60px;
    }
    .banner__title {
        font-size: 40px;
    }
}
